import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Profil from "./Profil";
import NavBar from "./NavBar";
import Footer from "./Footer";
import About from "./About";
import Contact from "./Contact";
import Projects from "./Projects";

const App = () => {
  return (
    <Router>
      <main>
        <NavBar />
        <div className="content">
          <Switch>
            <Route exact path="/" component={Profil} />
            <Route path="/contact" component={Contact} />
            <Route path="/about" component={About} />
            <Route path="/projects" component={Projects} />
          </Switch>
          <Footer />
        </div>
      </main>
    </Router>
  );
};

export default App;
