const Profil = () => {
  return (
    <div className="profil">
      <div className="profil-info">
        <p className="profil-hello">Hello,{<br></br>} I am</p>
        <div className="profil-headline">
          <h1 className="profil-name">MERSAD AJANOVIC</h1>
          <h1 className="profil-title">FULLSTACK DEVELOPER</h1>
        </div>
      </div>
      <div className="profil-image"></div>
    </div>
  );
};

export default Profil;
