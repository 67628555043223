export const aboutMe = `<p>
Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet dolorem
asperiores a repellendus eos modi impedit sint aspernatur iste nobis,
quod quibusdam quam vitae sapiente esse blanditiis enim fuga
dignissimos.
</p><p>
Exercitationem placeat, veniam ab cupiditate voluptas praesentium
tempore saepe. Aspernatur autem illo nisi et porro ex dolorem esse
nemo saepe. Soluta maiores voluptatibus numquam odit. Fugiat provident
est ipsa quidem.
</p><p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p><p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>
<p>
Aspernatur eos labore et magnam, minus modi praesentium voluptatibus
quas asperiores sapiente? Iste dicta deserunt quod ad rem corrupti
rerum error accusamus? Voluptates sunt at tempora sed vero cum
necessitatibus!
</p>`;
