import { Link } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";
import { RiTwitterLine, RiGithubLine } from "react-icons/ri";

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-home">
        <Link to="/">
          <AiOutlineHome></AiOutlineHome>
        </Link>
      </div>
      <div className="navbar-sm">
        <a
          href="https://twitter.com/mersadajan"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RiTwitterLine></RiTwitterLine>
        </a>
        <a
          href="https://github.com/flamerged"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RiGithubLine></RiGithubLine>
        </a>
        <a
          href="https://www.instagram.com/mersadajan/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineInstagram></AiOutlineInstagram>
        </a>
        <a
          href="https://www.linkedin.com/in/mersadajanovic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineLinkedin></AiOutlineLinkedin>
        </a>
      </div>
      <div className="navbar-links">
        <Link to="/about">ABOUT</Link>
        <Link to="/projects">PROJECTS</Link>
        <Link className="navbar-contact" to="/contact">
          CONTACT
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;
