import { useRef, useState } from "react";
import { Divider } from "./Divider";
import { AiOutlineInstagram, AiOutlineLinkedin } from "react-icons/ai";
import { RiTwitterLine } from "react-icons/ri";

const Contact = () => {
  const [popMessage, setPopMessage] = useState("");
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const popupRef = useRef(null);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name: nameRef.current.value,
        email: emailRef.current.value,
        message: messageRef.current.value,
      }),
    })
      .then(() => {
        nameRef.current.value = "";
        emailRef.current.value = "";
        messageRef.current.value = "";
        setPopMessage("Message sent. Thank you!");
        popupRef.current.classList.add("show");
        setTimeout(() => {
          popupRef.current.classList.remove("show");
        }, 5000);
      })
      .catch(() => {
        setPopMessage("Error. Message not sent.");
        popupRef.current.classList.add("show");
        setTimeout(() => {
          popupRef.current.classList.remove("show");
        }, 5000);
      });
  };

  return (
    <div className="container">
      <div className="popup-message" ref={popupRef}>
        {popMessage}
      </div>
      <h1>GET IN TOUCH</h1>
      <Divider></Divider>
      <div className="sm-links">
        <a
          href="https://twitter.com/mersadajan"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RiTwitterLine></RiTwitterLine>
        </a>
        <a
          href="https://www.instagram.com/mersadajan/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineInstagram></AiOutlineInstagram>
        </a>
        <a
          href="https://www.linkedin.com/in/mersadajanovic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineLinkedin></AiOutlineLinkedin>
        </a>
      </div>
      <Divider></Divider>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-inputs">
          <label>
            <input type="text" required name="name" ref={nameRef} />
            <div className="input-label">Your name</div>
          </label>
          <label>
            <input type="text" required name="email" ref={emailRef} />
            <div className="input-label">Your E-Mail</div>
          </label>
        </div>
        <label>
          <textarea
            name="contact-message"
            id="contact-message"
            cols="30"
            rows="10"
            ref={messageRef}
            required
          ></textarea>
          <div className="textarea-label">Your Message</div>
        </label>
        <button type="submit">SEND</button>
      </form>
    </div>
  );
};

export default Contact;
