import { Divider } from "./Divider";
import ReactHtmlParser from "react-html-parser";
import { hobbies } from "../data/hobbies";
import { aboutMe } from "../data/about-me";

const About = () => {
  return (
    <div className="container">
      <AboutIntro></AboutIntro>
      <Divider></Divider>
      <Hobbies />
    </div>
  );
};

const AboutIntro = () => {
  return (
    <div className="about-intro">
      <div className="about-intro-image"></div>
      <h1>WHO AM I?</h1>
      <div className="about-intro-text">{ReactHtmlParser(aboutMe)}</div>
    </div>
  );
};

const Hobbies = () => {
  const content = hobbies;

  return (
    <div className="hobbies">
      <h1 className="hobbies-title">HOBBIES & INTERESTS</h1>
      {content.map((hobby, index) => {
        return <Hobby key={index} hobby={hobby} index={index} />;
      })}
    </div>
  );
};

const Hobby = ({ hobby, index }) => {
  const { interest, image, text } = hobby;

  return (
    <div className={index % 2 > 0 ? "hobby-entry-right" : "hobby-entry-left"}>
      <div className="hobby-main">
        <img
          src={require("../img/" + image).default}
          alt=""
          className={index % 2 > 0 ? "hobby-image-right" : "hobby-image-left"}
        />
        <h2 className="hobby-name">{interest.toUpperCase()}</h2>
        <p className="hobby-text">{ReactHtmlParser(text)}</p>
      </div>
      <div
        className={index % 2 > 0 ? "hobby-buttons-right" : "hobby-buttons-left"}
      >
        {/* {links.map((link, index) => {
          return (
            <a
              href={link.url}
              target="_blank"
              rel="noreferrer"
              className="hobby-button"
              key={index}
            >
              {link.name.toUpperCase()}
            </a>
          );
        })} */}
      </div>
    </div>
  );
};

export default About;
