import { ImHeart } from "react-icons/im";
import { AiOutlineCopyrightCircle } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="footer">
      <p>
        Made with <ImHeart className="footer-heart" /> by me{"    "}
        <AiOutlineCopyrightCircle className="footer-copyright" />
        {new Date().getFullYear()}
      </p>
    </div>
  );
};

export default Footer;
