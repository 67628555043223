export const hobbies = [
  {
    interest: "Video Games",
    image: "instagram-starwars.jpg",
    text:
      "Lorem ipsum dolor,<br><br> sit amet consectetur adipisicing elit. Amet dolorem asperiores a repellendus eos modi impedit sint aspernatur <a href='google.com' rel='noreferrer' class='inline-link'>Liste</a> nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos. nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos.nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos.",
    links: [{ name: "Backlog", url: "google.com" }],
  },
  {
    interest: "Video Games",
    image: "instagram-starwars.jpg",
    text:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet dolorem asperiores a repellendus eos modi impedit sint aspernatur iste nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos.",
    links: [{ name: "Backlog", url: "google.com" }],
  },
  {
    interest: "Video Games",
    image: "instagram-starwars.jpg",
    text:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet dolorem asperiores a repellendus eos modi impedit sint aspernatur iste nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos.",
    links: [{ name: "Backlog", url: "google.com" }],
  },
  {
    interest: "Video Games",
    image: "instagram-starwars.jpg",
    text:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet dolorem asperiores a repellendus eos modi impedit sint aspernatur iste nobis,quod quibusdam quam vitae sapiente esse blanditiis enim fuga dignissimos.",
    links: [{ name: "Backlog", url: "https://google.com" }],
  },
];
